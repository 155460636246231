<template>
  <div>
    <div class="d-flex flex-column">
      <div class="payment-method-title mt-6 mb-3">
        <p tabindex="0" class="my-0 text-center text-uppercase">
          Standing order details
        </p>
      </div>

      <v-card outlined class="grey lighten-3 rounded-md">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div class="mb-3">
              <p tabindex="0" class="text-caption my-0 font-weight-light">
                Account name
              </p>
              <p tabindex="0" class="text-caption mt-n1">
                {{ $store.state.paymentDetails.BankAccountName }}
              </p>
            </div>
            <div class="mb-3 text-right">
              <p tabindex="0" class="text-caption my-0 font-weight-light">
                Payment reference
              </p>
              <p tabindex="0" class="text-caption mt-n1 text-right">
                {{ $store.state.requestID }}
              </p>
            </div>
          </div>
          <div class="mt-n3 d-flex justify-space-between text-body-1">
            <p tabindex="0" class="my-0 font-weight-black">Amount</p>
            <p tabindex="0" class="my-0 font-weight-black">
              GBP
              {{
                $store.state.paymentDetails.Request.Breakdown[0].Amount.toFixed(
                  2
                )
              }}
            </p>
          </div>
          <v-divider class="my-1"></v-divider>
          <div class="d-flex justify-space-between">
            <p tabindex="0" class="text-caption my-0 font-weight-light">
              Frequency
            </p>
            <p tabindex="0" class="text-caption my-0 font-weight-light">
              {{ $store.state.paymentDetails.Request.Frequency }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <p tabindex="0" class="text-caption my-0 font-weight-light">
              First payment
            </p>
            <p tabindex="0" class="text-caption my-0 font-weight-light">
              {{ formattedStartDate }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <p tabindex="0" class="text-caption my-0 font-weight-light">
              Final payment
            </p>
            <p
              tabindex="0"
              v-if="$store.state.paymentDetails.Request.FinalPayment"
              class="text-caption my-0 font-weight-light"
            >
              {{ formattedEndDate }}
            </p>
            <p tabindex="0" v-else class="text-caption my-0 font-weight-light">
              No end date set
            </p>
          </div>
        </v-card-text>
      </v-card>

      <div class="payment-method-title mt-6 mt-sm-10 mb-3">
        <p tabindex="0" class="my-0 text-center text-uppercase">payment from</p>
      </div>

      <SelectedBankAndLogo />

      <p tabindex="0" class="text-center text-body-1 text-sm-h6 mt-12">
        You will be securely redirected to your bank to authenticate this
        payment.
      </p>

      <v-btn
        @click="postPayment"
        :loading="loading"
        class="mb-3"
        x-large
        depressed
        color="primary"
        block
      >
        confirm & continue
      </v-btn>

      <ModulrTermsAndConditions />
    </div>
  </div>
</template>

<script>
import ModulrTermsAndConditions from "@/components/ModulrTermsAndConditions.vue";
import SelectedBankAndLogo from "@/components/SelectedBankAndLogo.vue";
import { API } from "aws-amplify";
import moment from "moment/src/moment";

export default {
  name: "PaymentConfirmation",
  components: {
    ModulrTermsAndConditions,
    SelectedBankAndLogo,
  },
  data() {
    return {
      overlayLoader: false,
      loading: false,
    };
  },
  methods: {
    reRoute() {
      this.overlayLoader = true;
      this.$router.push({ name: "PaymentSuccess" });
    },
    postPayment() {
      this.loading = true;

      const postParams = {
        body: {
          payload: { payments: [] },
          modulrBankID: this.$store.state.selectedBank.ModulrID,
          requestID: this.$store.state.requestID,
          payMethod: "Transfer", // hard coded for Modulr bank transfers
          initialPayment: this.$store.state.paymentDetailsToSubmit.startDate,
          finalPayment: this.$store.state.paymentDetailsToSubmit.endDate,
        },
      };

      API.post("paymentAppRest", "/modulr", postParams).then((res) => {
        this.loading = false;
        if (res.status === "200") {
          if (res.modulrURL.modulrID) {
            this.$store.commit("SET_MODULR_ID", res.modulrURL.modulrID); // API request returns ModulrID and URL for reroute
            if (this.$store.state.safariBrowser) {
              // if Safari browser - set a number of session cookies to 5 mins
              this.createCookie("modulrID", res.modulrURL.modulrID, 5);
              this.createCookie(
                "payAgentRequestID",
                this.$store.state.requestID,
                5
              );
              this.createCookie(
                "payAgentPaymentDetails",
                JSON.stringify(this.$store.state.paymentDetails),
                5
              );
              this.createCookie(
                "payAgentPaymentDetailsSubmitted",
                JSON.stringify(this.$store.state.paymentDetailsToSubmit),
                5
              );
            }
            // window.open(res.modulrURL.url, "_self")
            window.location.href = res.modulrURL.url;
          }
          if (res.modulrURL.error) alert(res.modulrURL.error);
        } else {
          console.log("Error: ", res);
          this.loading = false;
        }
      });
    },
    createCookie(name, value, minutes) {
      let expires;
      if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires;
    },
  },
  computed: {
    formattedStartDate() {
      return this.$store.state.paymentDetailsToSubmit.startDate
        ? moment(this.$store.state.paymentDetailsToSubmit.startDate).format(
            "DD/MM/YYYY"
          )
        : "";
    },
    formattedEndDate() {
      return this.$store.state.paymentDetailsToSubmit.endDate
        ? moment(this.$store.state.paymentDetailsToSubmit.endDate).format(
            "DD/MM/YYYY"
          )
        : "";
    },
  },
};
</script>
